import {Component, OnInit, Inject, Input} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TrackingService} from '../../services/tracking.service';
import {AuthService} from '../../services/auth.service';
import {TranslateService} from '@ngx-translate/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {DOCUMENT} from '@angular/common';
import {ApiService} from '../../services/api.service';
import {Router} from '@angular/router';
import {RedirectService} from '../../services/redirect.service';
import {ToastrService} from 'ngx-toastr';
import { environment } from '../../../environments/environment';
import { WINDOW } from '../../services/window.service';

/**
 * Popup Component
 *
 * @author Fran Aragon
 *
 * Used to show pop ups with management options
 */
@Component({
    selector: 'app-popup',
    templateUrl: './popup.component.html',
    styleUrls: ['./popup.component.css']
})
export class PopupComponent implements OnInit {

    /**
     * Session hash
     */
    private hash: string;

    /**
     * Payment data for tpv.
     */
    public tpvData: any;

    /**
     * Data values of the fine to use in multi-language texts
     */
    public _data2popups: any;

    /**
     * Indicates if can pay with a discount
     */
    public isprontopago = true;

    /**
     * Data value to use in multi-language text
     */
    public fechaFinEnlaceString: string;

    /**
     * Data value to use in multi-language text
     */
    public fechaProntoPagoString: string;

    /**
     * Used to show a diferent popup
     */
    public estado: string;

    /**
     * Indicate role of logged user (0: Gestor Flota, 1: Conductor)
     */
    public rolAcceso: number;

    /**
     * Indicate role who has permission to opperate fine (0: Gestor Flota, 1: Conductor)
     */
    public rolPermiso: number;

    /**
     * Indicate state of enlace (1: enviado, 2: pagado, 3: identificado, 4: compartido)
     */
    public acceso: number;

    public timeOut =  false;
    public diffDays: number;
    public diffHrs: number;
    public diffMins: number;
    public tipoInfraccion: any;
    public showModal = true;
    public showCancelButton = false;
    public showPaymentCancelationModal = false;
    public finesToCancel: any;
    public loadingFinesToCancel = false;
    public loadingCancelation = false;
    public showCancelationMessage = false;
    public finesToCancelTable = [];
    public expediente: any;
    public fines = [];


    /**
     * @ignore
     */
    constructor(private modalService: NgbModal,
                private trackingService: TrackingService,
                private router: Router,
                private http: HttpClient,
                private apiService: ApiService,
                @Inject(DOCUMENT) private _document,
                private translate: TranslateService,
                private redirectService: RedirectService,
                private authService: AuthService,
                private toastr: ToastrService,
                @Inject(WINDOW) private window: Window) {

        this.hash = this.authService.getHash();
    }

    /**
     * Configures input data obtained of viewfine parent component to display values in multi-language text
     */
    @Input() set data2popups(value: any[]) {
        this._data2popups = value;

        this.estado = this._data2popups.estado;
        this.rolAcceso = this._data2popups.rolAcceso;
        this.rolPermiso = this._data2popups.rolPermiso;
        this.acceso = this._data2popups.acceso;
        this.tipoInfraccion = this._data2popups.tipoInfraccion;

        const fechaProntoPago = this._data2popups.fechaProntoPago.date;
        const fechaHoraServidor = this._data2popups.fechaHoraServidor.date;
        const fechaFinEnlace = this._data2popups.fechaFinEnlace.date;

        const fechaFinEnlacef = new Date(fechaFinEnlace.replace(' ', 'T'));
        const fechaProntoPagof = new Date(fechaProntoPago.replace(' ', 'T'));
        const fechaHoraServidorf = new Date(fechaHoraServidor.replace(' ', 'T'));

        if (this._data2popups.tipoEnlace === '2COM') {
            if (fechaHoraServidorf.getTime() < fechaProntoPagof.getTime()) {
                this.isprontopago = true;
            } else {
                this.isprontopago = false;
            }
        }

        if (fechaProntoPagof.getHours() === 0 && fechaProntoPagof.getMinutes() === 0) {
            this.fechaProntoPagoString = fechaProntoPagof.getDate().toString() + '-' + (fechaProntoPagof.getMonth() + 1).toString() + '-' + fechaProntoPagof.getFullYear().toString();
        } else {
            this.fechaProntoPagoString = fechaProntoPagof.getDate().toString() + '-' + (fechaProntoPagof.getMonth() + 1).toString() + '-' + fechaProntoPagof.getFullYear().toString() + ' ' + this.addTimeZero(fechaProntoPagof.getHours()) + ':' + this.addTimeZero(fechaProntoPagof.getMinutes());
        }

        if (fechaFinEnlacef.getHours() === 0 && fechaFinEnlacef.getMinutes() === 0) {
            this.fechaFinEnlaceString = fechaFinEnlacef.getDate().toString() + '-' + (fechaFinEnlacef.getMonth() + 1).toString() + '-' + fechaFinEnlacef.getFullYear().toString();
        } else {
            this.fechaFinEnlaceString = fechaFinEnlacef.getDate().toString() + '-' + (fechaFinEnlacef.getMonth() + 1).toString() + '-' + fechaFinEnlacef.getFullYear().toString() + ' ' + this.addTimeZero(fechaFinEnlacef.getHours()) + ':' + this.addTimeZero(fechaFinEnlacef.getMinutes());
        }
        this.getFines();
    }

    getFines() {
        this.expediente = JSON.parse(localStorage.getItem('expedientes'));
        this.apiService.getFineData(`${this.hash}`)
            .subscribe((data: any) => {
                    for (let i = 0; i < data.length; i++) {
                        let importe = data[i].importeAPagar;
                        if (importe === undefined) {
                            importe = 0;
                        }
                        const fechaHoraServidor = data[i].fechaHoraServidor.date;
                        const fechaFinEnlace = data[i].fechaFinEnlace.date;
                        const dateExpires = new Date(fechaFinEnlace.replace(' ', 'T'));
                        const nowdate = new Date(fechaHoraServidor.replace(' ', 'T'));
                        let timeOut = false;
                        let diffMs;
                        let diffDays;
                        let diffHrs;
                        let diffMins;
                        const estados = {
                            '1': 'enviado',
                            '2': 'pagado',
                            '3': 'identificado',
                            '4': 'compartido',
                            '5': 'vencido',
                            '6': 'rechazado',
                            '7': 'multibanco'
                        };
                        let estado = estados[data[i].acceso];
                        if (data[i].acceso === 7) {
                            this.showCancelButton = true;
                        }
                        let statusLabel = '';
                        if (nowdate.getTime() > dateExpires.getTime()) {
                            timeOut = true;
                        } else {
                            diffMs = Math.abs(dateExpires.getTime() - nowdate.getTime() );
                            diffDays = Math.floor(diffMs / 86400000);
                            diffHrs = Math.floor((diffMs % 86400000) / 3600000);
                            diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);
                        }
                        let remainingTime = diffDays + 'd ' + diffHrs + 'h ' + diffMins + 'm';
                        if (diffMs === undefined) {
                            remainingTime = '0d 0h 0m';
                        }
                        if (data[i].estado === 'enviado' && timeOut || data[i].estado === 'cerrado') {
                            estado = 'vencido';
                        }
                        if (data[i].estado === 'multibanco') {
                            estado = 'multibanco';
                        }
                        switch (estado) {
                            case 'enviado':
                                statusLabel = this.translate.instant('FineGroupPage.Data.StatusType.Pending');
                                break;
                            case 'compartido':
                                statusLabel = this.translate.instant('FineGroupPage.Data.StatusType.Shared');
                                break;
                            case 'pagado':
                                statusLabel = this.translate.instant('FineGroupPage.Data.StatusType.Paid');
                                break;
                            case 'identificado':
                                statusLabel = this.translate.instant('FineGroupPage.Data.StatusType.Identified');
                                break;
                            case 'rechazado':
                                statusLabel = this.translate.instant('FineGroupPage.Data.StatusType.Rejected');
                                break;
                            case 'vencido':
                                statusLabel = this.translate.instant('FineGroupPage.Data.StatusType.Expired');
                                break;
                            case 'multibanco':
                                statusLabel = this.translate.instant('FineGroupPage.Data.StatusType.Multibanco');
                                break;
                            default:
                                break;
                        }

                        this.fines[i] = {
                            isChecked: false,
                            estado: estado,
                            statusLabel: statusLabel,
                            acceso: data[i].acceso,
                            rolAcceso: data[i].rolAcceso,
                            rolPermiso: data[i].rolPermiso,
                            cliente: data[i].cliente,
                            referencia: data[i].expedienteGesthispania,
                            nombreOrganismo: data[i].nombreOrganismo,
                            lugar: data[i].lugar,
                            tipoInfraccion: data[i].tipoInfraccion,
                            matricula: data[i].matricula,
                            fechaInfraccion: data[i].fechaDenuncia.slice(0, 10),
                            remainingTime: remainingTime,
                            importeAPagar: importe,
                            importeBrutoConDescuento: data[i].importeBrutoConDescuento,
                            comisionConDescuento: data[i].comisionConDescuento,
                            timeOut: timeOut
                        };
                        if (this.fines[i].acceso === 7) {
                            this.showCancelButton = true;
                        }
                    }
                },
                error => {
                    console.log(error);
                });
    }

    checkMultibancoCancelledSources() {
        this.showModal = false;
        this.showPaymentCancelationModal = true;
        this.loadingFinesToCancel = true;
        const URL_API = environment.URL_API;
        const url = URL_API + 'comprobar-cancelacion/' + this.authService.getHash();
        const params = {
            expedientes: JSON.parse(localStorage.getItem('expedientes'))
        };
        const headers: HttpHeaders = new HttpHeaders().set('TOKEN', this.authService.getToken());
        return this.http.post<any>(url, params, {headers}).subscribe(
            data => {
                this.loadingFinesToCancel = false;
                this.finesToCancel = data;
                this.drawFinesToCancel();
            },
            error => {
                console.log(error);
                this.loadingFinesToCancel = false;
            }
        );
    }

    drawFinesToCancel() {
        this.finesToCancelTable = [];
        for (let i = 0; i < this.fines.length; i++) {
            for (let j = 0; j < this.finesToCancel.length; j++) {
                if (this.fines[i].referencia === this.finesToCancel[j]) {
                    const tr = {
                        referencia: this.fines[i].referencia,
                        nombre: this.fines[i].cliente,
                        matricula: this.fines[i].matricula,
                        organismo: this.fines[i].nombreOrganismo,
                        lugar: this.fines[i].lugar,
                        importeAPagar: this.fines[i].importeAPagar
                    };
                    this.finesToCancelTable.push(tr);
                }
            }
        }
    }

    cancelMultibanco() {
        this.loadingCancelation = true;
        const params = {
            expedientes: this.finesToCancel
        };
        const URL_API = environment.URL_API;
        const url = URL_API + 'cancelar-pago/' + this.authService.getHash();
        const headers: HttpHeaders = new HttpHeaders().set('TOKEN', this.authService.getToken());
        return this.http.post<any>(url, params, {headers}).subscribe(
            data => {
                this.loadingCancelation = false;
                this.showCancelationMessage = true;
            },
            error => {
                this.loadingCancelation = false;
                this.showCancelationMessage = true;
            }
        );
    }

    exit() {
        this.window.location.reload();
    }

    /**
     * @ignore
     */
    private addTimeZero(i): string {
        if (i < 10) {
            i = '0' + i;
        }
        return i;
    }

    /**
     * Open modal and call tracking method
     */
    popup1(modal1, event) {
        this.modalService.dismissAll();
        this.modalService.open(modal1, {size: 'lg', centered: true, windowClass: 'animated fadeIn'});
        // this.myEvent(event);
    }

    /**
     * Open modal and call tracking method
     */
    popup2(modal2, event) {
        this.modalService.dismissAll();
        this.modalService.open(modal2, {size: 'lg', centered: true, windowClass: 'animated fadeIn'});
        // this.myEvent(event, 'LAW');
    }

    /**
     * Open modal and call tracking method
     */
    popup3(modal3, event) {
        this.modalService.dismissAll();
        this.modalService.open(modal3, {size: 'lg', centered: true, windowClass: 'animated fadeIn'});
        // this.myEvent(event, 'LAW');
    }

    /**
     * Open modal and call tracking method
     */
    popuprecurso(modalrecurso, event) {
        this.modalService.dismissAll();
        this.modalService.open(modalrecurso, {size: 'lg', centered: true, windowClass: 'animated fadeIn'});
        // this.myEvent(event);
    }

    /**
     * Close modal and call tracking method
     */
    legalOption(event) {
        this.modalService.dismissAll();
        // this.myEvent(event, 'LAW');
    }

    /**
     * @ignore
     */
    ngOnInit() {
        const fechaHoraServidor = this._data2popups.fechaHoraServidor.date;
        const fechaFinEnlace = this._data2popups.fechaFinEnlace.date;
        const dateExpires = new Date(fechaFinEnlace.replace(' ', 'T'));
        const nowdate = new Date(fechaHoraServidor.replace(' ', 'T'));
        if (nowdate.getTime() > dateExpires.getTime()) {
            this.timeOut = true;
        } else {
            const diffMs = Math.abs(dateExpires.getTime() - nowdate.getTime() );
            this.diffDays = Math.floor(diffMs / 86400000);
            this.diffHrs = Math.floor((diffMs % 86400000) / 3600000);
            this.diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);
        }
    }

    /**
     * Request payment tpv parameters, create ann send an automatic form with tpv parameters, using a redirectService
     */
    initPay(modalRedirect) {

        this.apiService.initPayProcess(`${ this.hash }/iniciapago`).subscribe(
            (response: any) => {

                this.modalService.dismissAll();
                this.modalService.open(modalRedirect, {centered: true, windowClass: 'animated fadeIn'});

                this.tpvData = response;

                const params = {
                    Ds_SignatureVersion: this.tpvData.Ds_SignatureVersion,
                    Ds_MerchantParameters: this.tpvData.Ds_MerchantParameters,
                    Ds_Signature: this.tpvData.Ds_Signature
                };


                this.redirectService.post(params, this.tpvData.URL);

            },
            error => {

                this.errorEvent(error.status, error.message);

                if (error.status === 409) {
                    this.modalService.dismissAll();
                    const errMsg: any = this.translate.instant('ErrorsMessages.InitPay');
                    this.toastr.warning(errMsg);
                    return false;
                } else {
                    this.modalService.dismissAll();
                    this.authService.logout();
                    this.router.navigate([`/app/login/Payyourfines/${ this.hash }`], {queryParams: {error: error.status}});
                }
            }
        );

    }

    /**
     * Captures component event and sends event data to analytics.
     */
    myEvent(event, type = 'SHOW_POPUP') {
        if (this.trackingService.getPermissionTracking() === 'all' || this.trackingService.getPermissionTracking() === 'statistics') {

            this.trackingService.setHash(this.hash);

            this.trackingService.actionEvent(event, type);

            // Google Analytics
            const idElement: string = (event.target as Element).id;
            const typeEvent: string = event.type;
            const eventAction: string = 'idelement: ' + idElement + ' - typeEvent: ' + typeEvent;
            // (<any>window).ga('send', 'event', {
            //     eventCategory: 'Popup Events',
            //     eventLabel: 'PopupEvents',
            //     eventAction: eventAction,
            //     eventValue: 10
            // });

        }

    }

    /**
     * Captures component error event and saves it in localstorage using tracking service and sends error event data to analytics.
     */
    errorEvent(status, message) {
        if (this.trackingService.getPermissionTracking() === 'all' || this.trackingService.getPermissionTracking() === 'statistics') {

            this.trackingService.setHash(this.hash);

            this.trackingService.errorEvent(status, message);

            // Google Analytics
            const eventAction: string = 'error message: ' + message + ' - error code status: ' + status;
            // (<any>window).ga('send', 'event', {
            //     eventCategory: 'Popup Error Events',
            //     eventLabel: 'PopupErrorEvents',
            //     eventAction: eventAction,
            //     eventValue: 10
            // });

        }
    }

    paymentForm() {
        this.router.navigate([`app/payment/${this.hash}`]);
        this.modalService.dismissAll();
    }

    identificationForm() {
        this.router.navigate([`app/identify/${this.hash}`]);
        this.modalService.dismissAll();
    }

    shareForm() {
        this.router.navigate([`app/share/${this.hash}`]);
        this.modalService.dismissAll();
    }

    rejectForm() {
        this.router.navigate([`app/reject/${this.hash}`]);
        this.modalService.dismissAll();
    }

    allowActionButton(): boolean {
        // console.log(`timeout: ${this.timeOut}\nacceso: ${this.acceso}\nrolAcceso: ${this.rolAcceso}\nrolPermiso: ${this.rolPermiso}`);
        let allowed = false;
        if (this.timeOut) {
            allowed = false;
        } else {
            if (this.acceso === 1) {
                if (this.rolAcceso === this.rolPermiso) {
                    allowed = true;
                }
            } else {
                if ((this.rolAcceso === 1 || this.rolAcceso === 2) && this.acceso === 4 || this.acceso === 7) {
                    allowed = true;
                }
            }
        }
        return allowed;
    }

}
